@font-face {
    font-family: 'Sequel100Black-75';
    src: url('../fonts/Sequel100Black-75.woff2') format('woff2');
    font-style: normal;
}
@font-face {
    font-family: "Mark Pro";
    src: url("../fonts/FontFont - Mark Pro Bold.woff") format("woff");
    font-weight: 700;
}
@font-face {
    font-family: "Mark Pro";
    src: url("../fonts/FontFont - Mark Pro Medium.woff") format("woff");
    font-weight: 500;
}